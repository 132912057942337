import React, { useCallback, useEffect, useState } from "react";
import { Edit, Plus, DragIcon } from "../../../components/icons";
import "./styles.scss";
import { get, isElement, isEmpty, orderBy, size } from "lodash";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Spin, notification } from "antd";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { async } from "rxjs";
const HostName = process.env.REACT_APP_API_HOSTNAME;

export default function ShowsComponent(props) {
  const [list, setList] = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [nextStep, setNextStep] = useState(0);
  const [isCreateSeason, setIsCreateSeason] = useState("true");
  // const [previousStep, setPreviousStep] = useState(0);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [megaPhoneLoading, setMegaPhoneLoading] = useState(false);
  const [syncPremiumEpiLoading, setSyncPremiumEpiLoading] = useState(false);

  const { state } = location;

  const [checkingInterval, setCheckingInterval] = useState(0);

  //   // Use the useEffect hook to clear the interval when the component unmounts
  //   useEffect(() => {
  //     return () => {
  //       if (checkingInterval) {
  //         clearInterval(checkingInterval);
  //       }
  //     };
  //   }, [checkingInterval]);

  useEffect(() => {
    props.getAllShows({
      pageNumber: !location?.state?.pageNumber
        ? 1
        : location?.state?.pageNumber,
      pageSize: 60,
    });
    const callApi = async () => {
      if (location?.state?.pageNumber) {
        setPage(location?.state?.pageNumber);
      }
      const { value } = await props.getAllData();

      if (get(value, "success", "") === true) {
        setAllRecords(value?.data);
      }
    };
    callApi();
  }, []);

  // useEffect(() => {
  //   if (checkingInterval) {
  //     performCheck();
  //   }
  // }, [checkingInterval, performCheck]);

  const startChecks = () => {
    // Perform the initial check immediately
    // performCheck();
    // Schedule recurring checks every 5 minutes (300,000 milliseconds)
    // const intervalId = setInterval(performCheck, 1 * 60 * 1000);
    // setChecking(true);
    // Store the interval ID in state to clear it later
    // setCheckingInterval(intervalId);
  };

  // const performCheck = useCallback(async () => {
  //   await axios
  //     .get(`${HostName}/api/v1/admin/checkSyncRecord`)
  //     .then(async (res) => {
  //       if (res?.data?.status === "completed") {
  //         setIsLoading(false);
  //         openNotificationWithIcon("success", "Sync episode done successfully");
  //         //   clearInterval(checkingInterval);
  //         clearInterval(0);
  //       } else if (res?.data?.status === "failed") {
  //         setIsLoading(false);
  //         openNotificationWithIcon("error", "Something went wrong");
  //         //   clearInterval(checkingInterval);
  //         clearInterval(0);
  //       } else {
  //         setTimeout(() => {
  //           setCheckingInterval(checkingInterval + 1);
  //         }, 100000);
  //       }
  //     })
  //     .catch((err) => {
  //       setCheckingInterval(checkingInterval + 1);
  //     });
  // }, [checkingInterval]);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Gen-Z-Media",
      description: message,
    });
  };
  const syncShowFromArt19 = async () => {
    setIsLoading(true);
    openNotificationWithIcon("success", "Started syncing new episodes.");

    axios
      .get(`${HostName}/api/v1/admin/syncingEpisodes`)
      .then(async (res) => {
        if (res?.data?.success) {
          await axios
            .get(`${HostName}/api/v1/admin/disableButton`)
            .then(async (res) => {
              if (res?.data?.success) {
                await axios
                  .get(`${HostName}/api/v1/admin/syncNewEpisodes`)
                  .then((res) => {
                    // startChecks();
                    // performCheck();
                    // if (res?.data?.success) {
                    //   setIsLoading(false);
                    //   openNotificationWithIcon(
                    //     "success",
                    //     "Sync episode done successfully"
                    //   );
                    // }
                  })
                  .catch((err) => {
                  });
              } else {
                openNotificationWithIcon("error", "Something went wrong");
              }
            })
            .catch((err) => {
              setIsLoading(false);
              openNotificationWithIcon("error", "Something went wrong");
              console.log("err", err);
            });
        } else {
          setIsLoading(false);
          openNotificationWithIcon(
            "error",
            "Episode syncing is already on progress"
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const syncShowFromMegaphone = async () => {
  //   setLoader(true);
  //   openNotificationWithIcon("success", "Started syncing new episodes.");
  //   try {
  //     const res = await axios.get(`${HostName}/api/v1/admin/syncMegaphoneEpisodes`);
  //     if (res?.data?.success) {
  //       setLoader(false);
  //       openNotificationWithIcon("success", "Sync episode done successfully");
  //     } else {
  //       setLoader(false);
  //       openNotificationWithIcon("error", "Something went wrong");
  //     }
  //   } catch (err) {
  //     setLoader(false);
  //     openNotificationWithIcon("error", "Something went wrong");
  //     console.log("err", err);
  //   }
  // };

  const syncShowFromMegaphone = async () => {
    setMegaPhoneLoading(true);
    openNotificationWithIcon("success", "Started syncing new episodes.");
    axios
      .get(`${HostName}/api/v1/admin/syncingMegaPhoneEpisodes`)
      .then(async (res) => {
        if (res?.data?.success) {
          await axios
            .get(`${HostName}/api/v1/admin/megaPhoneDisableButton`)
            .then(async (res) => {
              if (res?.data?.success) {
                //   await axios
                //     .get(`${HostName}/api/v1/admin/syncNewEpisodes`)
                //     .then((res) => {
                //       if (res?.data?.success) {
                //         setIsLoading(false);
                //         openNotificationWithIcon(
                //           "success",
                //           "Sync episode done successfully"
                //         );
                //       }
                //     });
                await axios
                  .get(`${HostName}/api/v1/admin/syncMegaphoneEpisodes`)
                  .then((res) => {
                    setMegaPhoneLoading(false);
                    openNotificationWithIcon(
                      "success",
                      "Sync episode done successfully"
                    );
                  })
                  .catch((err) => {
                    setMegaPhoneLoading(false);
                  });
              } else {
                setMegaPhoneLoading(false);
                openNotificationWithIcon("error", "Something went wrong");
              }
            })
            .catch((err) => {
              setMegaPhoneLoading(false);
              openNotificationWithIcon("error", "Something went wrong");
              console.log("err", err);
            });
        } else {
          setMegaPhoneLoading(false);
          openNotificationWithIcon(
            "error",
            "Episode syncing is already on progress"
          );
        }
      })
      .catch((err) => {
        setMegaPhoneLoading(false);
        console.log("err", err);
      });
    // try {
    //   const res = await axios.get(
    //     `${HostName}/api/v1/admin/syncMegaphoneEpisodes`
    //   );
    //   if (res?.data?.success) {
    //     setLoader(false);
    //     openNotificationWithIcon("success", "Sync episode done successfully");
    //   } else {
    //     setLoader(false);
    //     openNotificationWithIcon("error", "Something went wrong");
    //   }
    // } catch (err) {
    //   setLoader(false);
    //   openNotificationWithIcon("error", "Something went wrong");
    // }
  };

  const syncPremiumEpiFromMegaphone = async () => {
    setSyncPremiumEpiLoading(true)
    openNotificationWithIcon("success", "Started syncing new episodes.");
    axios
      .get(`${HostName}/api/v1/admin/syncingMegaPremiumRecords`)
      .then(async (res) => {
        if (res?.data?.success) {
          await axios
            .get(`${HostName}/api/v1/admin/megaPhonePremiumDisableButton`)
            .then(async (res) => {
              if (res?.data?.success) {
                //   await axios
                //     .get(`${HostName}/api/v1/admin/syncNewEpisodes`)
                //     .then((res) => {
                //       if (res?.data?.success) {
                //         setIsLoading(false);
                //         openNotificationWithIcon(
                //           "success",
                //           "Sync episode done successfully"
                //         );
                //       }
                //     });
                await axios
                  .get(`${HostName}/api/v1/admin/syncMegaphonePremiumEpisodes`)
                  .then((res) => {
                    setSyncPremiumEpiLoading(false)
                    openNotificationWithIcon(
                      "success",
                      "Sync episode done successfully"
                    );
                  })
                  .catch((err) => {
                    setSyncPremiumEpiLoading(false)
                  });
              } else {
                setSyncPremiumEpiLoading(false)
                openNotificationWithIcon("error", "Something went wrong");
              }
            })
            .catch((err) => {
              setSyncPremiumEpiLoading(false)
              openNotificationWithIcon("error", "Something went wrong");
              console.log("err", err);
            });
        } else {
          setSyncPremiumEpiLoading(false)
          openNotificationWithIcon(
            "error",
            "Episode syncing is already on progress"
          );
        }
      })
      .catch((err) => {
        setSyncPremiumEpiLoading(false)
        console.log("err", err);
      });
    // try {
    //   const res = await axios.get(
    //     `${HostName}/api/v1/admin/syncMegaphoneEpisodes`
    //   );
    //   if (res?.data?.success) {
    //     setLoader(false);
    //     openNotificationWithIcon("success", "Sync episode done successfully");
    //   } else {
    //     setLoader(false);
    //     openNotificationWithIcon("error", "Something went wrong");
    //   }
    // } catch (err) {
    //   setLoader(false);
    //   openNotificationWithIcon("error", "Something went wrong");
    // }
  };

  useEffect(() => {
    if (get(props, "showPhase", "") === "success") {
      // let datas = orderBy(props.showData, ["orderIndex"], ["asc"]);
      let datas = props.showData;
      setList(datas);
      let nextPageCount = parseInt(Math.ceil(props.showCount / 60));
      setNextStep(nextPageCount);
      props.resetLoginPhase();
    } else if (get(props, "showPhase", "") === "error") {
      props.resetLoginPhase();
    }
  }, [props, list]);

  const pageChange = (value) => {
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      props.getAllShows({ pageNumber: pageNum, pageSize: 60 });
    } else if (value === "previous") {
      // let prev = page - 2
      // setPreviousStep(prev)
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      props.getAllShows({ pageNumber: pageNum, pageSize: 60 });
    }
  };

  const onDragEnd = (result) => {
    const newItems = Array.from(list);
    const [removed] = newItems.splice(result?.source?.index, 1);
    newItems.splice(result?.destination?.index, 0, removed);
    setList(newItems);
    // let newIndexdata = list.find((d) => d.orderIndex === result?.source?.index);
    // let oldIndexdata = list.find((d) => d.orderIndex === result?.destination?.index);
    // if(!isEmpty(newIndexdata) && !isEmpty(oldIndexdata)){
    //   let seriesData = [{_id: newIndexdata?._id,orderIndex: result?.source?.index },{_id: oldIndexdata?._id,orderIndex:result?.destination?.index}]
    props.updateOrderIndex({ seriesData: newItems });
    // }
  };

  const headers = [
    { label: "Series Id", key: "seriesId" },
    { label: "Season Id", key: "seasonId" },
    { label: "Series Name", key: "seriesName" },
    { label: "Season Name", key: "seasonName" },
    { label: "Series Description", key: "seriesDescription" },
    { label: "Season Description", key: "seasonDescription" },
    { label: "Season Number", key: "seasonNumber" },
    { label: "Episode Id", key: "episodeId" },
    { label: "Episode Name", key: "episodeName" },
    { label: "Episode Description", key: "episodeDescription" },
    { label: "Episode Number", key: "episodeNumber" },
  ];
  return (
    <>
      {/* <main className="main" role="header-with-tabs">
        <div className="main__header">
          <TopNavigationComponent onTabChange={onTabChange} props={props} />
        </div>*/}
      <div className="page__header">
        <div className="page__header--container">
          <div className="page__header--row">
            <div className="page__header--left">
              <h1 className="page__header--title">Shows</h1>
            </div>
            <div className="page__header--right">
              {/* <button
                onClick={() => {
                  console.log("navigated");
                  navigate(`/megaphone-shows`, {
                    // state: { data: l },
                  });
                }}
                type="button"
                className="btn btn__green"
              >
                Megaphone Shows
              </button> */}
              <button
                onClick={() => syncPremiumEpiFromMegaphone()}
                type="button"
                className="btn btn__green"
              >
                {syncPremiumEpiLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner"></div>
                  </div>
                ) : (
                  <>
                    <Plus className="btn__icon" />
                    Sync New Premium Episodes from Megaphone
                  </>
                )}
              </button>
              <button
                onClick={() => syncShowFromMegaphone()}
                type="button"
                className="btn btn__green"
              >
                {megaPhoneLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner"></div>
                  </div>
                ) : (
                  <>
                    <Plus className="btn__icon" />
                    Sync New Episodes from Megaphone
                  </>
                )}
              </button>
              {/* <button
                onClick={() => {
                  console.log("navigated");
                  navigate(`/create-shows`, {
                    // state: { data: l },
                  });
                }}
                type="button"
                className="btn btn__green"
              >
                Create Show
              </button> */}
              {/* <button
                onClick={() => syncShowFromArt19()}
                type="button"
                className="btn btn__green"
              >
                {isLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner"></div>
                  </div>
                ) : (
                  <>
                    <Plus className="btn__icon" />
                    Sync New Episodes from Art19
                  </>
                )}
              </button> */}
              {allRecords.length > 0 && (
                <CSVLink
                  data={allRecords?.map((item) => {
                    return {
                      ...item,
                      ...{
                        seriesDescription: item?.seriesDescription
                          ? item?.seriesDescription
                            .replaceAll("\n", "")
                            .replaceAll(", ", "")
                            .replaceAll(",", "")
                          : "-",
                        seasonName: item?.seasonName ? item?.seasonName : "-",
                        seasonId: item?.seasonId ? item?.seasonId : "-",
                        seasonDescription: item?.seasonDescription
                          ? item?.seasonDescription
                            .replaceAll("\n", "")
                            .replaceAll(", ", "")
                            .replaceAll(",", "")
                          : "-",
                        seasonNumber: item?.seasonNumber
                          ? item?.seasonNumber
                          : "-",
                        // episodeNumber: item?.episodeNumber
                        //   ? item?.episodeNumber
                        //   : "-",
                        // episodeId: item._id,
                        // episodeName: item.title,
                        episodeDescription: item?.episodeDescription
                          ? item?.episodeDescription
                            .replaceAll("\n", "")
                            .replaceAll(", ", "")
                            .replaceAll(",", "")
                          : "-",
                      },
                    };
                  })}
                  headers={headers}
                  filename="Data.csv"
                  className="btn btn__green"
                  // className="btn btn-primary"
                  target={"_blank"}
                >
                  Export Data
                </CSVLink>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="custom__container">
        <div className="table-responsive table__responsive--custom">
          {list.length === 0 ? (
            <Spin
              size="large"
              indicator={
                <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
              }
              className="loader__full"
            />
          ) : (
            <div className="table table__custom drag-table-container">
              <div className="draggable-continer-head">
                <div className="title-block">Show Titles</div>
                <div className="status">Episodes</div>
                <div className="status">Source</div>
                <div className="status date-time-call">Last Updated Date</div>
                <div className="status">Status</div>
                <div className="status">Home Featured Episode</div>
              </div>
              {/* {list.length > 0 &&
                  list.map((l, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          "text-decoration": "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/show-details/${l._id}`, {
                            state: { data: l },
                          })
                        }
                      >
                        {l.title}
                      </td>
                      <td
                        onClick={() =>
                          navigate(`/show-details/${l._id}`, {
                            state: { data: l },
                          })
                        }
                      >
                        {l.appDisplayStatus === "active" ? (
                          <span
                            className="table__status active"
                            style={{ cursor: "pointer" }}
                          >
                            Active
                          </span>
                        ) : (
                          <span
                            className="table__status not-active"
                            style={{ color: "#FF69B4", cursor: "pointer" }}
                          >
                            Not Active
                          </span>
                        )}
                      </td>
                      <td>
                        {!l.featuredEpisodeId.length &&
                        !l.featuredSeasonId.length ? (
                          <button
                            onClick={() =>
                              navigate(`/show-details/${l._id}`, {
                                state: { data: l },
                              })
                            }
                            className="btn btn__link--underline"
                          >
                            ADD +
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              navigate(`/show-details/${l._id}`, {
                                state: { data: l },
                              })
                            }
                            className="btn btn__link--underline"
                            style={{ color: "#5ABDCE" }}
                          >
                            {l.featuredSeasonId[0]?.season_number && (
                              <> S{l.featuredSeasonId[0]?.season_number}</>
                            )}
                            {l.featuredEpisodeId[0]?.episodeNumber && (
                              <> E{l.featuredEpisodeId[0]?.episodeNumber}: </>
                            )}
                            {!l.featuredEpisodeId[0]?.episodeNumber && <> </>}
                            {l.featuredEpisodeId[0]?.title}
                          </button>
                        )}
                      </td>
                      <td className="table__cell--last">
                        <button
                          onClick={() =>
                            navigate(`/show-details/${l._id}`, {
                              state: { data: l },
                            })
                          }
                          className="btn"
                        >
                          <Edit />
                        </button>
                      </td>
                    </tr>
                  ))} */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      className="timeline-block scroll-block"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {list.map((l, i) => 
                      l?.isMegaphone === true ? (
                          <Draggable key={l._id} draggableId={l._id} index={i}>
                            {(provided, snapshot) => (
                              <div
                                className="draggable-row"
                                key={l._id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                provided={provided}
                                snapshot={snapshot}
                              >
                                <div
                                  className="title-block"
                                  style={{
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate(`/show-details/${l._id}`, {
                                      state: { data: l, pageNumber: page },
                                    })
                                  }
                                >
                                  <DragIcon className="drag-icon" />
                                  {l?.title}
                                </div>
                                <div className="status-column">
                                  {l?.totalEpisodes}
                                </div>
                                {l?.isNetwork === "Audio Adventures" ? (
                                  <div className="status-column">Ad</div>
                                ) : (
                                  <div className="status-column">Premium</div>
                                )}
                                <div
                                  className="status-column date-time-call"
                                  onClick={() =>
                                    navigate(`/show-details/${l._id}`, {
                                      state: { data: l },
                                    })
                                  }
                                >
                                  <span
                                    className="table__status active"
                                    style={{ color: "black" }}
                                  >
                                    {moment(l.updatedAt).format(
                                      "MM/DD/YYYY, hh:mm A"
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="status-column"
                                  onClick={() =>
                                    navigate(`/show-details/${l._id}`, {
                                      state: { data: l },
                                    })
                                  }
                                >
                                  {l.appDisplayStatus === "active" ? (
                                    <span
                                      className="table__status active"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Active
                                    </span>
                                  ) : (
                                    <span
                                      className="table__status not-active"
                                      style={{
                                        color: "#FF69B4",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Not Active
                                    </span>
                                  )}
                                </div>
                                <div className="description-column- status-column">
                                  {!l.featuredEpisodeId.length &&
                                    !l.featuredSeasonId.length ? (
                                    <button
                                      onClick={() =>
                                        navigate(`/show-details/${l._id}`, {
                                          state: { data: l },
                                        })
                                      }
                                      className="btn btn__link--underline text-start"
                                    >
                                      ADD +
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        navigate(`/show-details/${l._id}`, {
                                          state: { data: l },
                                        })
                                      }
                                      className="btn btn__link--underline text-start"
                                      style={{ color: "#5ABDCE" }}
                                    >
                                      {l.featuredSeasonId[0]?.season_number && (
                                        <>
                                          {" "}
                                          S
                                          {l.featuredSeasonId[0]?.season_number}
                                        </>
                                      )}
                                      {l.featuredEpisodeId[0]
                                        ?.episodeNumber && (
                                          <>
                                            {" "}
                                            E
                                            {
                                              l.featuredEpisodeId[0]
                                                ?.episodeNumber
                                            }
                                            :{" "}
                                          </>
                                        )}
                                      {!l.featuredEpisodeId[0]
                                        ?.episodeNumber && <> </>}
                                      {l.featuredEpisodeId[0]?.title}
                                    </button>
                                  )}
                                </div>

                                {isCreateSeason === "true" && (
                                  <div className="table__cell--last"></div>
                                )}
                                {/* { isCreateSeason === "true" &&
                         <div className="table__cell--last">
                         </div>
                        } */}
                              </div>
                            )}
                          </Draggable>
                      ): null
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          {list.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(list)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {page - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {nextStep - page > 0 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*  </main>
       */}
    </>
  );
}
